import { Controller } from 'stimulus'
import { usePostMessage } from '~shared/PostMessage'
import { customFetch } from '@/lib/customFetch'

const post = usePostMessage()

// TODO: Get Rid of the Rest of this class.
// it just makes it more complex to manage state in two controllers
// instead of properly using targets and connect callbacks
export default class extends Controller {

  static targets = [
    'createRosterButton',
    'expandTableBtn',
    'hideTableButton',
    'query',
    'settings',
    'visibility'
  ]
  static values = { action: String }
  // Storing settings in local settings
  get settings() {
    const settings = {
      showSummary: true,
      expandedTable: true,
    }

    const existing = JSON.parse(localStorage.getItem('attendance-settings') || '{}')

    Object.assign(settings, existing)

    if ('show_summary' in settings) {
      const { show_summary, expanded_table } = settings
      delete settings.show_summary
      delete settings.expanded_table

      settings.showSummary = show_summary
      settings.expandedTable = expanded_table

      localStorage.setItem('attendance-settings', JSON.stringify(settings))
    }

    return Object.assign(settings, existing)
  }

  get currentDate () {
    const rawDate = new URLSearchParams(window.location.search).get('date')

    if (String(rawDate).match(/^\d{4}-\d{2}-\d{2}$/)) {
      return rawDate
    }

    let date
    try {
      if (String(rawDate).match(/^\d{2..4}[\/-]\d{2}[\/-]\d{2..4}$/)) {
        date = new Date(`${rawDate} 00:00:00 ${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
      } else {
        date = new Date(rawDate || new Date())
      }
    } catch (e) {
      logger.error('Error parsing date', e)
      date = new Date()
    }

    return moment(date).format('YYYY-MM-DD')
  }

  connect(){
    this.onUpdate()
  }

  setSettings(setting, value) {
    const settings = this.settings
    const valueWas = settings[setting]
    settings[setting] = value
    localStorage.setItem('attendance-settings', JSON.stringify(settings))
    post('kipu_attendance_settings', { settings: Object.assign({}, settings), changed: setting, value, valueWas })

    return Object.assign({}, settings)
  }

  onUpdate() {
    this.setSummaryVisibility()
    this.setCompactAttTable()
    this.changeRosterButtonText()
    this.setButtonVisibility()
  }

  toggleSummaryVisibility() {
    const { showSummary } = this.settings
    this.setSettings('showSummary', !showSummary)

    this.setSummaryVisibility()
  }

  toggleCompactAttTable() {
    this.setSettings('expandedTable', !this.settings.expandedTable)

    this.setCompactAttTable()
  }

  setCompactAttTable() {
    if (this.settings.expandedTable === false) {
      $('.attendance-image').hide();
      $('.attendance-timestamp').hide();
      $('#photo-column-th').hide()
      if (this.hasExpandTableBtnTarget) {
        this.expandTableBtnTarget.innerText = 'Show Expanded Table';
      }
    } else {
      $('.attendance-image').show();
      $('.attendance-timestamp').show();
      $('#photo-column-th').show();
      if (this.hasExpandTableBtnTarget) {
        this.expandTableBtnTarget.innerText = 'Show Compact Table';
      }
    }
  }

  setSummaryVisibility() {
    let table = document.getElementById('attendance-loc-table')
    if (this.settings.showSummary === false) {
      table?.classList?.add('tw-hidden')
      if (this.hasHideTableButtonTarget) {
        this.hideTableButtonTarget.innerText = 'Show Summary'
      }
    } else {
      table?.classList?.remove('tw-hidden')
      if (this.hasHideTableButtonTarget) {
        this.hideTableButtonTarget.innerText = 'Hide Summary'
      }
    }
    table.display = ''
  }

  setButtonVisibility() {
    const visible = [].slice.call(document.getElementsByClassName('attendance-card')).filter(function(el) {
      return window.getComputedStyle(el).getPropertyValue('display') !== 'none'
    })

    for (const target of this.visibilityTargets) {
      target.classList.remove('hide')
      target.style.display = ''

      if (!visible.length && target.dataset.showWhenEmpty === 'false') {
        target.classList.add('tw-hidden')
      } else if (visible.length > 0 && target.dataset.showWhenEmpty === 'true') {
        target.classList.add('tw-hidden')
      } else {
        target.classList.remove('tw-hidden')
      }
    }
  }

  changeRosterButtonText() {
    let coll = document.getElementsByClassName('attendance-card')
    if (coll.length > 0) {
      this.createRosterButtonTarget.innerText = 'Update Roster'
    } else {
      this.createRosterButtonTarget.innerText = 'Create Roster'
    }
  }

  addPatientModal() {
    const currentDate = this.currentDate
    const url = new URL('/attendances/patient_attendance_modal', window.location.href)
    url.searchParams.set('attendance_date', currentDate)
    Kipu.keepWaitMessage++
    $('#ajax_working').show()
    customFetch(url, { html: true })
      .then(response => response.text())
      .then((html) => {
        document.getElementById("custom-order-dialog").innerHTML = html;
        $("#custom-order-dialog").dialog({
          title: 'Add Patient',
          modal: true,
          resizable: true,
          width: 600,
          height: 400,
          open: function() {
            $('#attendance-patient-ids').select2({
              placeholder: "Add missing patients.",
              dropdownParent: $('#custom-order-dialog'),
              closeOnSelect: false,
              allowClear: true
            }).focus();
            $('.select2-search__field').click();
          }
        });
      })
      .catch(function(error){
        console.log('[ATTENDANCE] Add Patient Modal Error', error)
        document.getElementById("flash-message").innerHTML = error;
      })
      .finally(function() {
        Kipu.keepWaitMessage--
        if (Kipu.keepWaitMessage < 1) $('#ajax_working').hide()
      })
  }

  addPatientToAttendance(){
    const p = document.getElementById('attendance-patient-ids');
    const patient_ids = Array.from(p.selectedOptions).map(opt => opt.value);
    const flash = document.getElementById("flash-message");
    const body = JSON.stringify({patient_ids: patient_ids, attendance_date: this.currentDate})

    Kipu.keepWaitMessage++
    $('#ajax_working').show()

    if ($('#custom-order-dialog').length) $('#custom-order-dialog').dialog('close')

    customFetch("/attendances/", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body
    })
      .then(response => {
        if(response.ok) this.triggerReload()
        else throw new Error(response.statusText)
      })
      .catch((error) => {
        console.log('[ATTENDANCE] Add Patient Error', error)
        const message = error.result?.error
          || error.message
          || error.response?.statusText
          || error.toString()
        flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>'+ message + '</p></div>';
      })
      .finally(() => {
        Kipu.keepWaitMessage--
        if (Kipu.keepWaitMessage < 1) $('#ajax_working').hide()
      })
  }

  dispatchUpdate() {
    Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
      element.dispatchEvent(new Event('attendanceTableUpdated'))
    })
  }

  triggerReload() {
    window.dispatchEvent(new CustomEvent('reloadAttendancesTable'))
  }
}
